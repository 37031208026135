import Navbar from './Navbar';
import Footer from './Footer';

export default function Flexera(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-9 text-center tsm--content--image title--edit p-2'>Flexera Implementation and Support</h1>
                </div>
                {/* <h5 className='col-sm-10 mt-4 tsm--content--text title--edit p-3'>Enhance software asset management, license compliance, and cloud cost management by configuring Flexera solutions to align with organizational requirements. Ensures seamless data transfer, system integration, and ongoing assistance to improve asset visibility and compliance when transitioning from legacy systems to Flexera.</h5> */}
                <div className='flexera--background--image' style={{}}>
                </div>
                <div className='col-sm-12 mt-5 about--quotes--head'>
                    <p className='tsm--content--head--mobile'> Our Support</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Software Asset Management Optimization</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}} align="justify">By implementing Flexera, organizations can effectively track and manage software assets, ensuring that they are fully utilized and compliant with license agreements. This results in reduced overhead costs and mitigates the risk of non-compliance, while maximizing the value derived from existing software investments.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>License Compliance Assurance</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}} align="justify">Flexera's license management features provide organizations with real-time insights into software usage, helping them ensure that they are not over-licensed or under-licensed, preventing costly penalties from software vendors and audits.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p style={{fontSize:"25px"}}>Cloud Cost Management and Optimization</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}} align="justify">With increasing reliance on cloud services, Flexera helps organizations optimize cloud spending by providing visibility into cloud consumption, allowing them to identify unused or underutilized resources and reallocate them, ultimately saving on unnecessary costs.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Smooth Migration and Integration</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}} align="justify">During the migration to Flexera, the solution ensures a seamless transfer of data from legacy systems, integrating with existing IT systems, so that organizations can maintain continuity and leverage their existing technology investments.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 tsm--practice--align'>
                    <p className="sms--key-cap" style={{fontSize:"25px"}}>Comprehensive Post-Migration Support</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}} align="justify">After migrating to Flexera, ongoing support helps organizations fine-tune the system, ensuring that software asset management, license compliance, and cloud cost management continue to meet evolving business needs and compliance requirements.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 tsm--key--style'>
                    <div className='tsm--practice--align mt-5'>
                        <p className="sms--key-cap" style={{fontSize:"25px"}}>Key Capabilities Of Flexera Implementation Service</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"18px"}}>Optimizing Your IT Estate</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Software License Optimization</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>In-Depth Asset Insights</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Enhanced Compliance Reporting</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Manage hardware assets</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Continuous Support & Maintenance</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}